import { AspectRatio, Box, Container, Flex, Link, Text } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver, Elements } from '@kontent-ai/delivery-sdk';
import { toKebabCase, toTitleCase } from 'helpers/textCase';
import { colorScheme } from 'lib/colorScheme';
import { LinkType } from 'lib/enums/LinkType';
import { ContentPage, FeaturedSpots, Sector, ServiceLine, ThemeTopic } from 'models';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Translate } from 'next-translate';
import { normalize } from 'pages/api/sitemap';
import { FC, useEffect, useState } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { imageFormatter } from 'utils/imageFormatter';

import { IconComp } from '../base/IconComp';
import { Link as CustomLink } from '../base/Link';
import { HyphenResult } from '../hyphen/hyphenation';
import { mainSubtypeForOverviewAutomatic } from './OverviewBlock';

interface FeaturedContentProps {
  model: FeaturedSpots;
}
export const FeaturedContent: FC<FeaturedContentProps> = ({ model }) => {
  const translations = withTranslations();

  const router = useRouter();
  const [currentActive, setCurrentActive] = useState(0);
  const overviewPages = [
    translations.multiple('project'),
    translations.multiple('product'),
    translations.multiple('insight'),
  ];
  const headline = model.elements.headline.value;
  const hyphenHeadline = HyphenResult(headline, router.locale);

  const viewAllText = model.elements.cta.value;
  const mediaPosition =
    model.elements.content.linkedItems.length > 1 ? 'right' : model.elements.mediaPosition.value[0].codename;
  const contentPages = model.elements.content.linkedItems;
  const backgroundWidth = model.elements.backgroundWidth.value[0].codename;
  const backgroundColor =
    colorScheme[model.elements.backgroundColor.value[0].codename as keyof typeof colorScheme].base;
  const paddings = model.elements.paddings?.value.map((value) => value.codename);

  const colors =
    contentPages?.length === 1 && mediaPosition === 'left' ? ['white', backgroundColor] : [backgroundColor, 'white'];
  useEffect(() => {
    let i = currentActive;
    setInterval(increment, 5000);
    function increment() {
      i = i + 1;

      if (i === contentPages.length) {
        i = 0;
      }
      setCurrentActive(i);
    }
  }, []);
  const setPaddingsTop = () => {
    const top = paddings.filter((item) => item.includes('top')).join(',');

    return {
      pt: top.includes('all') ? 0 : top.includes('half') ? { base: 'xs', lg: 's' } : { base: 's', lg: 'l' },
    };
  };

  const setPaddingsBottom = () => {
    const bottom = paddings.filter((item) => item.includes('bottom')).join(',');

    return {
      pb: bottom.includes('all') ? 0 : bottom.includes('half') ? { base: 'xs', lg: 's' } : { base: 's', lg: 'l' },
    };
  };

  const HeadlineBlock = () => {
    const urlByPath = router.asPath.slice(0, router.asPath.lastIndexOf('/'));
    const url =
      urlByPath.length && overviewPages.includes(urlByPath.split('/')[1])
        ? urlByPath
        : `/${translations.multiple(model.elements.content.linkedItems[0]?.elements.category.value[0]?.codename)}`;
    return (
      <Flex
        position={'relative'}
        bg={{ base: mediaPosition === 'right' ? backgroundColor : 'none', lg: 'none' }}
        sx={setPaddingsTop()}
        pb='xs'
        justifyContent='space-between'
        alignItems='center'
        pl={{ base: 'xs', lg: '0' }}
      >
        <Text
          as='h2'
          textStyle={{ base: 'desktop.body.book.l', lg: 'desktop.h5' }}
          fontWeight={{ base: 325, lg: 400 }}
          position={{ base: 'sticky', lg: 'relative' }}
          w={{ lg: mediaPosition === 'right' || contentPages.length > 1 ? 'calc(30%)' : '70%' }}
        >
          {hyphenHeadline}
        </Text>
        {/* --- View all button --- */}
        {viewAllText && (
          <Box position='relative'>
            <CustomLink
              /* Since we are following same category and subcategory of the page, setting this as per url works as intended */
              href={url}
              type={LinkType.SecondaryButton}
              iconName='chevronRightForLink'
              ariaLabel={`${translations.aria('viewAll')} ${headline}`}
              locale={router.locale}
            >
              {viewAllText}
            </CustomLink>
          </Box>
        )}
      </Flex>
    );
  };
  const getImageUrl = (page: ContentPage) => {
    const url = Object.keys(normalize(page, router.locale, undefined, true, translations))[0];
    return url;
  };
  const TextBlock = ({ active, page }: { active: boolean; page: ContentPage }) => {
    const url = Object.keys(normalize(page, router.locale, undefined, true, translations))[0];
    // This will return element of tag used for that category of the page, or undefined
    const tagCategory =
      mainSubtypeForOverviewAutomatic[
        page.elements.category.value[0].codename as keyof typeof mainSubtypeForOverviewAutomatic
      ];
    // Extract namespace from tagCategory (e.g. tagsThemeTopic => theme_topic)
    const tag = tagCategory
      ? (translations[toKebabCase(tagCategory).replace('tags_', '')] as Translate)(
          // Get the correct tag codename for category of the page and run it through translation (returns undefined if correct tag is not filled -> will not show tag)
          (page.elements[tagCategory] as Elements.TaxonomyElement<Sector | ThemeTopic | ServiceLine>).value[0]
            ?.codename as string,
        )
      : '';
    return (
      <>
        <Flex
          pointerEvents={active ? 'auto' : 'none'}
          direction='column'
          flexDirection='column-reverse'
          pb={{ base: 's', lg: 'm' }}
        >
          <Link href={url}>
            <Text
              as='h2'
              textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }}
              pt={{ base: 'xs', lg: '0' }}
              dangerouslySetInnerHTML={{ __html: page.elements.summaryTitle.value }}
            ></Text>
          </Link>
          {!!tag && (
            <Text textStyle={'mobile.body.s'} pt={{ base: 'xs', lg: '0' }} pb={{ lg: 'xs' }}>
              {toTitleCase(tag)}
            </Text>
          )}
        </Flex>
        <Link href={url}>
          <Box
            pb='s'
            textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.l' }}
            dangerouslySetInnerHTML={{
              __html: createRichTextHtmlResolver(nodeParser).resolveRichText({
                element: page.elements.summaryDescription,
              }).html,
            }}
          ></Box>
        </Link>
        <Box display={{ base: 'none', lg: 'unset' }}>
          <CustomLink
            href={url}
            colorScheme='mountain.base'
            isDisabled={!active}
            type={LinkType.SecondaryButton}
            iconName='chevronRightForLink'
            ariaLabel={`${translations.aria('learnMoreAbout')} ${page.elements.summaryTitle.value}`}
            locale={router.locale}
          >
            {translations.common('learnMore')}
          </CustomLink>
        </Box>
        <Box display={{ base: 'unset', lg: 'none' }}>
          <CustomLink
            href={url}
            colorScheme='cyan.web'
            type={LinkType.SecondaryButton}
            iconName='chevronRightForLink'
            ariaLabel={`${translations.aria('learnMoreAbout')} ${page.elements.summaryTitle.value}`}
            locale={router.locale}
          >
            {translations.common('learnMore')}
          </CustomLink>
        </Box>
      </>
    );
  };

  return (
    <Box
      id={model.system.codename}
      mx='auto'
      maxW={{ base: '100vw', lg: backgroundWidth === 'partial' ? '1440px' : moduleMaxWidth }}
      minW={`min(100vw, 1440px)`}
      sx={
        backgroundWidth === 'partial'
          ? {
              bgGradient: {
                base: `linear(to-b, ${colors[0]} 30%, ${colors[1]} 0%)`,
                sm: `linear(to-b, ${colors[0]} 40%, ${colors[1]} 0%)`,
                md: `linear(to-b, ${colors[0]} 45%, ${colors[1]} 0%)`,
                lg: `linear(to-r, ${colors[0]} 50%, ${colors[1]} 0%)`,
                xl: `linear(to-r, ${colors[0]} 55%, ${colors[1]} 0%)`,
              },
            }
          : { bg: backgroundColor }
      }
    >
      <Container px={0}>
        {/* -- Headline mobile -- */}
        {headline !== '' && (
          <Box display={{ base: 'block', lg: 'none' }}>
            <HeadlineBlock />
          </Box>
        )}
        {/* -- Headline mobile end -- */}
        <Container
          paddingInlineEnd={{ base: 0, sm: 0, md: 0, lg: 'l' }}
          paddingInlineStart={{ base: 'xs', sm: 'xs', md: 'xs', lg: 'l' }}
          zIndex={1}
          h={'auto'}
          position='relative'
          overflow={{ base: contentPages.length > 1 ? 'auto' : 'hidden', lg: 'hidden' }}
          sx={{
            '::-webkit-scrollbar': {
              width: '3px',
              height: '3px',
            },
            '::-webkit-scrollbar-track': {
              width: '3px',
              height: '3px',
            },
            '::-webkit-scrollbar-thumb': {
              background: 'cyan.web',
              borderRadius: '0',
            },

            scrollbarColor: 'var(--chakra-colors-mountain-20) blue',
            scrollbarWidth: 'thin',
          }}
        >
          {/* -- Actual Content -- */}
          <Box
            w={{ base: contentPages.length > 1 ? '90%' : '100%', lg: '100%' }}
            h='100%'
            position='relative'
            zIndex={1}
            id='RambollFeaturedContent'
          >
            {/* -- Headline desktop -- */}
            {headline !== '' && (
              <Box display={{ base: 'none', lg: 'block' }}>
                <HeadlineBlock />
              </Box>
            )}
            {/* -- Headline desktop end -- */}
            <Flex
              pt={{ base: 'xs', md: 's', lg: 'l' }}
              pr={{ base: 'xs', md: 's', lg: '0' }}
              sx={setPaddingsBottom()}
              w={contentPages.length === 1 ? { base: '100%', lg: 'auto' } : 'auto'}
              flexDirection={{
                base: 'column-reverse',
                lg: mediaPosition === 'left' && !contentPages[1] ? 'row-reverse' : 'row',
              }}
            >
              {/* -- Content Text -- */}
              <Box
                display={{ base: 'flex', lg: 'unset' }}
                position='relative'
                w={{ base: `${contentPages.length * 100}%`, lg: 'calc(30% + 80px)' }}
                zIndex={1}
              >
                {contentPages.map((page, index) => {
                  return (
                    <Box
                      key={index}
                      w='100%'
                      position={{ base: 'relative', lg: currentActive === index ? 'relative' : 'absolute' }}
                      opacity={{ base: '1', lg: currentActive === index ? '1' : '0' }}
                      transform={{
                        base: 'none',
                        lg: `translateX(${currentActive === index ? '0' : currentActive < index ? '50%' : '-50%'})`,
                      }}
                      transition='transform 0.5s, opacity 0.3s'
                      sx={mediaPosition === 'left' && !contentPages[1] ? { pl: { lg: 'l' } } : { pr: { lg: 'l' } }}
                      top='0'
                      left={{ base: `${index * 40}px`, lg: '0' }}
                    >
                      <TextBlock active={currentActive === index} page={page} />
                    </Box>
                  );
                })}
              </Box>
              {/* -- Content Text End -- */}

              {/* -- Image -- */}
              <Box w={{ base: '100%', lg: '70%' }} position='relative' zIndex='0'>
                <Flex
                  transform={{
                    base: 'unset',
                    lg: `translateX(calc(-${100 * currentActive}% - ${40 * currentActive}px))`,
                  }}
                  transition='transform 0.6s'
                >
                  {contentPages.map((page, index) => {
                    const ctaLink = getImageUrl(page);
                    const image = imageFormatter(page.elements.summaryImageFrontify?.value);

                    return (
                      <AspectRatio
                        key={index}
                        w='100%'
                        h='100%'
                        ratio={16 / 9}
                        opacity={{ base: '1', lg: index < currentActive ? '0' : '1' }}
                        transition={index < currentActive ? 'opacity 0.4s' : 'opacity 0.8s'}
                        position={index === 0 ? 'relative' : 'absolute'}
                        left={`calc(${100 * index}% + ${40 * index}px)`}
                      >
                        <Link href={ctaLink}>
                          <Image
                            src={image.src}
                            width={image.width}
                            height={image.height}
                            alt={image.alt}
                            sizes={currentActive === index ? '(max-width: 1024px) 100vw, 40vw' : '5vw'}
                          />
                        </Link>
                      </AspectRatio>
                    );
                  })}
                </Flex>
                {/* -- Buttons -- */}
                {contentPages.length > 1 && (
                  <Flex
                    w='auto'
                    gap='xxs'
                    position='relative'
                    left='100%'
                    transform='translateX(-95px)'
                    marginTop='s'
                    zIndex={2}
                    display={{ base: 'none', lg: 'flex' }}
                  >
                    <Box
                      as='button'
                      disabled={currentActive === 0}
                      w='43px'
                      cursor={currentActive === 0 ? 'default' : 'pointer'}
                      aria-label={translations.aria('moveToPrevious')}
                      aria-controls={'#RambollFeaturedContent'}
                      onClick={() => setCurrentActive(currentActive - 1)}
                    >
                      <IconComp iconName='prev' styleName={currentActive === 0 ? 'disabled' : 'default'} />
                    </Box>
                    <Box
                      as='button'
                      disabled={currentActive === contentPages.length - 1}
                      w='43px'
                      cursor={currentActive === contentPages.length - 1 ? 'default' : 'pointer'}
                      aria-label={translations.aria('moveToNext')}
                      aria-controls={'#RambollFeaturedContent'}
                      onClick={() => setCurrentActive(currentActive + 1)}
                    >
                      <IconComp
                        iconName='next'
                        styleName={currentActive === contentPages.length - 1 ? 'disabled' : 'default'}
                      />
                    </Box>
                  </Flex>
                )}
                {/* -- Buttons End -- */}
              </Box>
              {/* -- Image End -- */}
            </Flex>
          </Box>
          {/* -- Actual Content End -- */}

          {/* -- Design Content -- */}
          <Flex
            aria-hidden={true}
            w={{ base: contentPages.length > 1 ? `${contentPages.length * 90}vw` : '100%', lg: '100%' }}
            h='100%'
            position='absolute'
            top='0'
            left='0'
            margin='0'
            padding='0'
            flexDirection={{
              base: 'column',
              lg: mediaPosition === 'left' && !contentPages[1] ? 'row-reverse' : 'row',
            }}
          >
            <Box
              w={{ base: '100%', lg: '45%' }}
              h={{ base: '20%', lg: '100%' }}
              bg={{
                base: mediaPosition === 'right' || backgroundWidth === 'full' ? backgroundColor : 'white',
                lg: backgroundColor,
              }}
            ></Box>
            <Box
              w={{ base: '100%', lg: '55%' }}
              h='100%'
              bg={{
                base: mediaPosition === 'left' || backgroundWidth === 'full' ? backgroundColor : 'white',
                lg: backgroundWidth === 'full' ? backgroundColor : 'white',
              }}
            ></Box>
          </Flex>
          <Box
            position='absolute'
            h='100%'
            w={backgroundWidth === 'full' ? '100%' : '40%'}
            left={mediaPosition === 'right' || (backgroundWidth !== 'full' && contentPages[1]) ? '0' : 'unset'}
            right={mediaPosition === 'left' && !contentPages[1] ? '0' : 'unset'}
            top='0'
            bg={backgroundColor}
            display={{ base: 'none', lg: 'unset' }}
          ></Box>
          {/* -- Design Content End -- */}
        </Container>
        {/* -- Space under scrollbar -- */}
        <Box display={{ base: 'block', lg: 'none' }} p={'xs'} />
        {/* -- Space under scrollbar -- */}
      </Container>
    </Box>
  );
};
